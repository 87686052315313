<template>
  <section class="theme-section">
    <h3
      v-html="theme.name"
      class="title"
    />
    <div
      :class="getHeroContentClass(theme.type)"
      class="theme-content"
    >
      <div class="inner">
        <div
          v-for="(hero, i) in resultItem.heroes"
          :key="`hero-img${i}`"
          class="hero-img"
        >
          <img
            :src="require(`@images/hero/faces/lossless/${hero.id}.png`)"
            alt=""
          >
        </div>
        <div
          class="hero-content"
          :class="getHeroContentClass(theme.type)"
        >
          <div class="inner">
            <div class="hero-text">
              <section
                v-for="(hero, i) in resultItem.heroes"
                :key="`hero-text${i}`"
                class="item"
              >
                <p class="series" v-html="getTitle(hero.seriesId)" />
                <h4 class="name" v-html="hero.name" />
              </section>
            </div>
            <p class="count" v-html="$t('result003', { votes: formatVotes(resultItem.votes) })" />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { getLocaleByName } from '@/constants/locale'
import { delimit } from '@libraries/util/Math'

export default {
  name: 'ThemeSection',

  components: {
  },

  props: {
    titles: {
      type: Array,
      required: true
    },
    result: {
      type: Object,
      required: true
    }
  },

  computed: {
    locale () {
      return this.$route.params.lang
    },

    theme () {
      return this.result.theme
    },

    resultItem () {
      return this.result.results[0]
    }
  },

  methods: {
    // IEはflex-columnで揃えられたtextのword-wrapがおかしい
    getTitle (id) {
      return this.titles.find(title => title.id === id).title
    },

    getHeroContentClass (type) {
      const isPair = type === 'pair'
      return {
        '-single': !isPair,
        '-pair': isPair
      }
    },

    formatVotes (votes) {
      const localeKey = getLocaleByName(this.locale).value.alias
      return delimit(votes,localeKey)
    },
  }
}
</script>

<i18n lang="yaml" src="@/locales/result.yaml"></i18n>

<style lang="scss" scoped>
.theme-section {
  .title {
    $title: #{&};
    $fs: $fs-base * 2;
    background: url(#{$img-path}result/theme_title_bg.png) no-repeat center top;
    width: 650px;
    height: 186px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: $fs;
    line-height: lh(40, $fs);
    color: $color-sub;
    font-weight: 600;
    overflow: hidden;
    text-align: center;
    padding-left: 10px;
    padding-right: 10px;

    @at-root {
      #app {
        &:not(.-ja-jp) {
          #{$title} {
            font-size: $fs-middle;
          }
        }
        &.-zh-tw {
          #{$title} {
            font-size: $fs;
          }
        }
      }
    }
  }

  > .theme-content {
    position: relative;
    width: 620px;
    margin: 25px auto 65px; 
    height: 448px;
    border-style: solid;
    border-width: 50px;
    border-image: url(#{$img-path}result/theme_box_bg.png) 50 fill;

    > .inner {
      position: absolute;
      width: 600px;
      height: 430px;
      top: -40px;
      left: -40px;
      padding: 30px;
      display: flex;
      overflow: hidden;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
    }

    .hero-img {
      position: relative;
      width: 240px;
      img {
        position: relative;
        width: 240px;
        z-index: 2;
      }
      &:before {
        content: "";
        position: absolute;
        z-index: 1;
        top: -70px;
        left: -70px;
        width: 380px;
        height: 380px;
        display: block;
        background-image: url(#{$img-path}vote/bg_light.png);
        background-size: cover;
        background-position: center;
      }
    }


    &.-pair {
      .hero-img {
        &:nth-child(1) {
          margin-right: 20px;
        }
        &:nth-child(2) {
          margin-left: 20px;
        }
      }
    }
  }

  .hero-content {
    position: relative;
    z-index: 3;
    margin-top: 5px;

    > .inner {
      word-wrap: break-word;
    }

    .hero-text {
      display: flex;
      justify-content: center;
      width: 100%;
    }
      .item {
        display: flex;
        flex-direction: column;
        align-items: center;
        & +.item {
         margin: 0 0 0 20px;
        }
        &:first-child {
          margin-right: 20px;
        }
        &:last-child {
          margin-right: 0;
        }

        > .series {
          color: $color-em2;
          font-size: $fs-base;
          line-height: 1.1;
          text-align: center;
          letter-spacing: $ls-base;
          width: 100%;
        }
        > .name {
          color: $color-sub;
          font-size: $fs-xlarge;
          line-height: 1.1;
          white-space: nowrap;
          letter-spacing: $ls-base;
          margin-top: 5px;
        }
      }

    .count {
      color: $color-em;
      font-size: $fs-middle;
      font-weight: 600;
      letter-spacing: $ls-base;
      text-align: center;
      margin: 20px 20px 0;
    }

    // pair pattern
    &.-pair {
      width: 520px;
      > .inner {
        width: 520px;
      }
      .item {
        width: 50%;
      }
    }

    // single pattern
    &.-single {
      width: 520px;
      > .inner {
        width: 324px;
        margin: 0 auto;
      }
      .item {
        width: 100%;
      }
    }
  }
}
</style>
