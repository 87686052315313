<template>
  <div class="result">
    <confetti />
    <visual
      :confetti="false"
      class="result-visual"
    />
    <div class="main-content">
      <div class="content-inner">
        <h2 class="title">
          <img
            :src="titleImageSrc"
            width="750"
            height="248"
            :alt="$t('result001')"
          >
        </h2>
        <p class="message" v-html="$t('result002')" />
        <theme-section
          v-for="(result, i) in results"
          :key="i"
          :titles="titles"
          :result="result"
        />
        <action-button
          @click="goToList"
          class="list-button"
        >
          <span v-html="$t('result004')" />
        </action-button>
      </div>
    </div>
    <div class="misc-content">
      <back-button @click="goBack" />
      <action-button
        tag="a"
        href="https://fire-emblem-heroes.com/"
        target="_blank"
        class="-official"
      >
        <span v-html="$t('result005')" />
      </action-button>
    </div>

    <div class="page-footer">
      <sns-share
        shareText="common002"
        :replaceText="replaceText"
      />
      <app-download />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { sleep } from '@libraries/util/'
import Confetti from '@components/global/confetti.vue'
import Visual from '@components/global/visual.vue'
import ActionButton from '@components/global/button.vue'
import ThemeSection from '@components/result/theme-section.vue'
import BackButton from '@components/global/back-button.vue'
import SnsShare from '@components/global/sns-share.vue'
import AppDownload from '@components/global/app-download.vue'
import Api from '@libraries/api/'
import RequestHeroes from '@requests/heroes'
import RequestTitles from '@requests/titles'

export default {
  name: 'Result',

  components: {
    Confetti,
    Visual,
    ActionButton,
    BackButton,
    ThemeSection,
    SnsShare,
    AppDownload
  },

  props: {
    locale: {
      type: String,
      required: true
    }
  },

  data () {
    return {
      results: null
    }
  },

  computed: {
    ...mapGetters('loader', { loaderConfig: 'config' }),
    ...mapGetters('theme', { themeList: 'getThemes'}),
    ...mapGetters('result', {isResultOpen: 'isOpen', resultList: 'getResult'}),

    titleImageSrc () {
      return require(`@images/result/${this.locale}/main_title.png`)
    },

    replaceText () {
      return { url: `${location.origin}/${this.locale}/result/` }
    },
  },

  created () {
    if (!this.isResultOpen) {
      this.$router.replace({name: 'Top'})
    } 
  },

  async mounted () {
    await this.getTitles()
    await Promise.all([
      await this.getHeroes(),
      await sleep(this.loaderConfig.minDisplayDuration)
    ])
    this.$nextTick(async () => {
      await this.hideLoader()
    })
  },

  methods: {
    ...mapActions('loader', { hideLoader: 'hide' }),

    async getTitles () {
      await Api.call(new RequestTitles({ locale: this.locale }))
        .then((titles) => {
          this.titles = titles
        })
        .catch(({ error }) => {
          // eslint-disable-next-line
          console.error(error)
          this.$router.push({ name: 'Error' })
        })
    },

    async getHeroes () {
      await Api.call(new RequestHeroes({ locale: this.locale }))
        .then((_heroes) => {
          if (this.resultList) {
            this.results = this.resultList.map((result) => {
              const theme = this.themeList.find(theme => theme.id === result.themeId)
              const results = result.results.map((r) => {
                const rank = r.rank
                const votes = r.votes
                const heroes = r.hero_ids.map(heroId => _heroes.find(hero => hero.id === heroId))
                return { rank, votes, heroes }
              })
              return { theme, results }
            })
          }
        })
        .catch(({ error }) => {
          // eslint-disable-next-line
          console.error(error)
          this.$router.push({ name: 'Error' })
        })
    },

    goToList () {
      this.$router.push({
        name: 'ResultList',
        params: {
          themeType: 1
        }
      })
    },

    goBack () {
      this.$router.push({ name: 'Top' })
    },
  }
}
</script>

<i18n lang="yaml" src="@/locales/result.yaml"></i18n>

<style lang="scss" scoped>
.result {
  .confetti {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 600px;
    z-index: 0;
  }

  .result-visual {
    position: relative;
    z-index: 1;
  }

  .main-content {
    background:
      url(#{$img-path}result/main_bg_top.png) no-repeat center top,
      url(#{$img-path}result/main_bg_bottom.png) no-repeat center bottom;
    position: relative;
    margin-top: -50px;
    width: 736px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 174px;
    padding-bottom: 184px;
  }
    .content-inner {
      background: url(#{$img-path}result/main_bg_repeat.png) repeat-y center top;

      > .title {
        position: absolute;
        top: -80px;
        margin-left: -7px;
        margin-right: -7px;
      }
      > .message {
        font-size: $fs-middle;
        text-align: center;
        padding: 11px 40px 30px;
      }
      > .list-button {
        $this: #{&};
        width: 600px;
        margin-left: auto;
        margin-right: auto;

        @at-root {
          #app {
            &.-es-mx {
              #{$this} {
                font-size: $fs-large;
                /deep/ .inner {
                  margin: 0 -30px;
                }
              }        
            }
          }
        }

        /deep/ .inner {// @FIXME 可能ならabsoluteを使わない方法をとる
          margin: 0 -40px;
          display: block;
          > span {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 100%;
          }
        }
      }
    }

  .misc-content {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-top: 10px;
    padding-bottom: 70px;

    > .button.-official {
      $this: #{&};
      width: 600px;
      margin-top: 24px;
      font-size: $fs-large;
      line-height: lh(38, $fs-large);
      white-space: nowrap;

      @at-root {
        #app {
          &.-es-es,
          &.-fr-ca,
          &.-fr-fr {
            #{$this} {
              font-size: $fs-middle;
            }        
          }
          &.-es-mx {
            #{$this} {
              font-size: $fs-xsmall;
            }        
          }
          &.-pt-br {
            #{$this} {
              font-size: $fs-small;
            }        
          }
        }
      }

      .inner {
        margin-left: -76px;
        margin-right: -76px;
      }
    }
  }
}
</style>
